import React from 'react';
import { FcConferenceCall, FcMoneyTransfer, FcElectronics, FcViewDetails, FcTimeline, FcBullish, FcDataProtection ,FcSelfServiceKiosk, FcTodoList, FcCurrencyExchange } from 'react-icons/fc';

/**
 * props:
 * - onClick
 */
function NewUserButton() {
    return (
        <button id="btnNewUser" className="btn btn-primary animate-up-2 me-2" data-bs-toggle="modal" data-bs-target="#modalUser">
            <FcConferenceCall />
            Novo Usuário
        </button>
    )
}

export default NewUserButton;