import React, { useState, useEffect, useRef } from 'react';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Symbols from '../Symbols/Symbols';
import Toast from '../../components/Toast/Toast';
import LogModal from '../../components/Logs/LogModal';
import { getSettings, updateSettings } from '../../services/SettingsService';
import { FcBusinessman, FcLibrary, FcKey, FcServices, FcMultipleSmartphones } from 'react-icons/fc';
import { FaBitcoin } from 'react-icons/fa';



function Settings() {

    const confirmPassword = useRef('');
    const [settings, setSettings] = useState({});
    const [notification, setNotification] = useState({});

    useEffect(() => {
        getSettings()
            .then(result => setSettings(result))
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
    }, []);

    function onInputChange(event) {
        setSettings(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    function onFormSubmit(event) {
        if ((settings.password || confirmPassword.current.value)
            && settings.password !== confirmPassword.current.value)
            return setNotification({ type: 'error', text: `Os campos Nova Senha e Confirmar Senha devem ser iguais !!` });

        updateSettings(settings)
            .then(result => {
                if (result)
                    setNotification({ type: 'success', text: `Configurações Salvas com Sucesso !!!` });
                else
                    setNotification({ type: 'error', text: result });
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
    }

    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4"><FcServices /> Configurações</h1>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <button id="btnLogs" className="btn btn-primary animate-up-2 me-2" data-bs-toggle="modal" data-bs-target="#modalLogs">
                                <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z" /><path fillRule="evenodd" d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z" clipRule="evenodd" /></svg>
                            </button>
                        </div>
                    </div>
                </div>

                <nav>
                    <div className="nav nav-tabs mb-4 flex-column flex-sm-row" id="nav-tab" role="tablist">
                        <a className="nav-item nav-link active" id="Usuarios-tab" data-bs-toggle="tab" href="#Usuarios" role="tab" aria-controls="Usuarios" aria-selected="true"><FcBusinessman /> Usuário</a>
                        <a className="nav-item nav-link" id="CryptoTab-tab" data-bs-toggle="tab" href="#CryptoTab" role="tab" aria-controls="CryptoTab" aria-selected="false"><FaBitcoin /> Cryptomoedas e Tokens</a>
                        <a className="nav-item nav-link" id="ConfAlerta-tab" data-bs-toggle="tab" href="#ConfAlertaTab" role="tab" aria-controls="ConfAlertaTab" aria-selected="false"><FcMultipleSmartphones /> Configurações de Alertas</a>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="Usuarios" role="tabpanel" aria-labelledby="Usuarios-tab">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-body border-0 shadow mb-1">
                                    <h2 className="h5 mb-4"><FcBusinessman /> Usuário</h2>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input className="form-control" id="email" type="email" placeholder="seuemail@dominio.com" defaultValue={settings.email} onChange={onInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="phone">Telefone</label>
                                                <input className="form-control" id="phone" type="text" placeholder="+55 27 123456789" defaultValue={settings.phone} onChange={onInputChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div>
                                                <label htmlFor="password">Nova Senha</label>
                                                <input className="form-control" id="password" type="password" placeholder="Digite sua Senha" onChange={onInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div>
                                                <label htmlFor="confirmPassword">Confirmar Nova Senha</label>
                                                <input ref={confirmPassword} className="form-control" id="confirmPassword" type="password" placeholder="Digite sua Senha" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                            <div className="col-sm-3">
                                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={onFormSubmit}>Salvar</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="tab-pane fade" id="CryptoTab" role="tabpanel" aria-labelledby="CryptoTab-tab">
                        <Symbols />

                    </div>
                    <div className="tab-pane fade" id="ConfAlertaTab" role="tabpanel" aria-labelledby="ConfAlerta-tab">
                        <div className="card card-body border-0 shadow mb-1">
                            <h2 className="h5 my-4"><FcMultipleSmartphones /> Configurações de Alertas</h2>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="sendGridKey">SendGrid Api Key</label>
                                        <input className="form-control" id="sendGridKey" type="password" placeholder="Enter the SendGrid API Key" defaultValue={settings.sendGridKey} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="twilioPhone">Telefone Twilio </label>
                                        <input className="form-control" id="twilioPhone" type="text" placeholder="Entre com o número de telefone do Twilio" defaultValue={settings.twilioPhone} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="twilioSid">Twilio SID</label>
                                        <input className="form-control" id="twilioSid" type="text" placeholder="Entre com o Twilio SID" defaultValue={settings.twilioSid} onChange={onInputChange} />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="twilioToken">Twilio Token</label>
                                        <input className="form-control" id="twilioToken" type="password" placeholder="Entre com o Twilio Token" defaultValue={settings.twilioToken} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="telegramBot">Nome do Telegram Bot</label>
                                        <input className="form-control" id="telegramBot" type="text" placeholder="Entre com o Nome do Telegram Bot" defaultValue={settings.telegramBot} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="telegramToken">Telegram Bot Token</label>
                                        <input className="form-control" id="telegramToken" type="password" placeholder="Entre com o Telegram Bot Token" defaultValue={settings.telegramToken} onChange={onInputChange} />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="telegramChat">Telegram Chat ID</label>
                                        <input className="form-control" id="telegramChat" type="text" placeholder="Entre com o Telegram Chat ID" defaultValue={settings.telegramChat} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                    <div className="col-sm-3">
                                        <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={onFormSubmit}>Salvar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
            <Toast type={notification.type} text={notification.text} />
            <LogModal file="system" />
        </ >
    )
}

export default Settings;