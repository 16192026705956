import React from 'react';

function NavBar() {
    return (
        <>
            <nav className="navbar navbar-dark navbar-theme-primary px-4 col-12 d-lg-none">
                <div>
                    <span className="sidebar-icon">
                        <img src="/img/favicon/favicon-32x32.png" height="32" width="32" alt="Beholder Logo"></img>
                    </span>
                    <span className="mt-1 ms-1 sidebar-text text-white"> Hydra 3.2</span>
                </div>
                <a className="navbar-brand me-lg-5" href="/dashboard">
                    <img className="navbar-brand-light" src="/img/brand/light.svg" alt="Beholder Logo" />
                </a>
                <div className="d-flex align-items-center">
                    <button className="navbar-toggler d-lg-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
            </nav>
        </>
    )
};

export default NavBar;